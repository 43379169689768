.c-layout {
  &__spinner {
    position: fixed;
    z-index: 4;
    background: rgba(0, 0, 0, 0.3);
    display: flex;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    align-items: center;
    justify-content: center;
  }

  &__cookies-link {
    color: $red;

    &:hover {
      color: $red;
    }
  }
}

.c-body {
  background: white;

  &--login {
    background-image: url('/img/background.jpg');
    background-size: cover;
    display: flex;
    justify-content: center;
    height: 100%;
    min-height: calc(100vh - 194px);
  }

  padding: 40px 0;
}

.c-nav {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  padding: 50px 0 20px 0;
  background-color: rgba(224, 221, 212, 0.2);

  &__logo {
    max-width: 135px;
  }

  &__container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__back-link {
    display: block;
    color: $brown-1;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    position: relative;
    padding-left: 30px;
    margin-top: 20px;

    &::before {
      content: '';
      position: absolute;
      width: 20px;
      height: 20px;
      background: url('/img/icons/arrow-left.svg');
      background-size: cover;
      top: 0;
      bottom: 0;
      left: 0px;
      margin: auto;
    }

    &:hover {
      color: $brown-1;
    }
  }

  &__title {
    color: $grey;
    text-transform: uppercase;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    margin: 7px 0 0 0;
    letter-spacing: 0.02em;
  }
}

.c-footer {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  padding: 20px;

  &__title {
    width: 100%;
    display: block;
    text-align: center;
    margin-bottom: 10px;
  }

  &__image {
    width: 100%;
    max-width: 550px;
  }

  &__help {
    width: 100%;
    text-align: center;
    font-size: 14px;
    padding: 10px 0;

    a {
      color: $black;
    }
  }
}
