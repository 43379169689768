.c-register {
  max-height: 700px;
  display: flex;
  flex-wrap: wrap;
  max-width: 620px;
  padding: 40px 10%;
  align-items: center;
  justify-content: center;
  background: white;
  border-radius: 0 8px 8px 0;
  margin: 100px 0;
  box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.5);
  z-index: 2;

  &__title,
  &__description {
    text-align: center;
    width: 100%;
  }

  &__terms {
    text-align: center;
    width: 100%;
  }

  &__inputs-container {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 20px;
    padding: 30px 0;
    max-width: 320px;
  }

  &__button {
    width: 200px;
  }

  &__logo {
    max-width: 240px;
  }
}

.c-login {
  @extend .c-register;
  padding: 40px;
  background: #eae6e0;
  border-radius: 8px 0 0 8px;
  box-shadow: none;

  &__container {
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
    justify-content: center;
  }

  &__inputs-container {
    @extend .c-register__inputs-container;
    padding-bottom: 0;
  }

  &__restore-password {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 19px;
    color: $brown-1;
    width: 100%;
    text-align: center;
    margin: 10px 0 30px 0;
  }

  &__button-container {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  &__button {
    width: 200px;
    background-color: white !important;
    color: $brown-1 !important;
  }

  &__button-modify {
    width: fit-content;
    margin-top: 20px;
    min-width: 200px;
    background-color: white !important;
    color: $brown-1 !important;
  }
}

@media (max-width: 1350px) {
  .c-register {
    padding: 40px;
    width: 50%;
  }
}

@media (max-width: 800px) {
  .c-register {
    width: 100%;
    margin: 60px 0 0 0;
    border-radius: 8px;
  }
}

@media (max-width: 600px) {
  .c-register {
    padding: 20px;
  }

  .container {
    padding: 0 20px !important;
  }
}
