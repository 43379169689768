.c-button {
  border: none;
  padding: 10px 15px;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: bold;
  line-height: 23px;
  text-align: center;
  letter-spacing: 0.05em;
  border-radius: 6px;
  background: $brown-3;
  color: #fff;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  min-width: 120px;

  &:hover {
    background: $red;
    transition: all 0.2s ease-in-out;
  }

  &--active {
    background: $red;
    transition: all 0.2s ease-in-out;
  }

  &--disabled {
    opacity: 0.2;
    cursor: not-allowed;
  }
}

.c-option-button {
  @extend .c-button;

  background: none;
  border: solid 1px $red;
  color: $red;
  padding: 7px 20px;
  margin-right: 15px;
  min-width: unset;

  &--active {
    background: $red;
    color: white;
  }

  &:hover {
    background: $red;
    color: white;
  }

  @media (max-width: 450px) {
    padding: 5px 10px;
  }

  @media (max-width: 370px) {
    font-size: 12px;
  }
}

.c-link {
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.02em;
  color: $red;
  background: none;
  border: none;
  padding: 0;
  border-bottom: 2px solid $red;

  &:hover {
    color: $red;
    text-decoration: none;
  }
}

.c-option-button-menu {
  position: relative;
  &__dropdown {
    display: none;
    position: absolute;
    top: 40px;
    right: 10px;
    left: auto;
    background: rgb(248, 248, 248);
    border-radius: 6px;
    overflow: hidden;
    box-shadow: 3px 3px 20px rgba(0, 0, 0, 0.15);

    &--open {
      display: block;
    }
  }
}
