.c-edit-event {
  display: flex;

  &__pending-tag {
    width: 100%;
    margin-bottom: 20px !important;
  }

  &__form {
    margin-left: 40px;
    width: 100%;
  }

  &__form-grid {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr;
    gap: 40px;
  }

  &__input {
    width: 100%;
  }

  &__margin-bottom {
    margin-bottom: 40px;
  }

  &__date-container {
    div:first-child {
      margin-bottom: 20px;
    }
  }

  &__buttons {
    display: flex;
    margin: 20px 0px 0 auto;
    justify-content: flex-end;
  }

  &__submit-button {
    display: block;

    &--delete {
      display: block;
      margin-right: 20px;
    }
  }
}

@media (max-width: 1100px) {
  .c-edit-event {
    &__sidebar {
      display: none;
    }

    &__form {
      margin-left: 0px;
    }
  }
}

@media (max-width: 900px) {
  .c-edit-event {
    &__form-grid {
      display: grid;
      width: 100%;
      grid-template-columns: 1fr;
      gap: 40px;
    }
  }
}
