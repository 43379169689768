.c-event {
  &__margin-container {
    display: flex;
    width: 100%;
  }

  &__attached-container {
    width: calc(100% - 300px);
  }

  &__share {
    min-width: 500px;
    margin-top: 20px;
  }

  &__share-title {
    color: $brown-3;
    font-size: 14px;
    line-height: 19px;
    display: block;
    text-align: right;
  }

  &__share-buttons {
    text-align: right;
    margin-top: 10px;
  }
}

@media (max-width: 1220px) {
  .c-event {
    &__share {
      min-width: 350px;
    }
  }
}

@media (max-width: 1100px) {
  .c-event {
    &__margin-container {
      flex-wrap: wrap;
      justify-content: flex-start;
    }

    &__share {
      min-width: 0px;
    }

    &__attached-container {
      width: 100%;
    }

    &__share-title,
    &__share-buttons {
      text-align: left;
    }
  }
}
