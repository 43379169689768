html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

$red: #e51d43;
$black: #000000;
$dark-grey: #3c3c3b;
$grey: #575756;
$light-grey: #f0f0ef;
$brown-1: #b2aa96;
$brown-2: #b9b29f;
$brown-3: #c9c2b3;
$brown-4: #e0ddd4;
$brown-5: #eae6e0;

@import '../public/fonts/fonts.scss';

@import '../pages/styles/login.scss';
@import '../pages/styles/confirm-user.scss';
@import '../pages/styles/intranet.scss';
@import '../pages/styles/edit-event.scss';
@import '../pages/styles/event.scss';
@import '../pages/styles/terms.scss';
@import '../pages/styles/help.scss';

@import '../layout/layout.scss';

@import '../components/inputs/inputs.scss';
@import '../components/buttons/buttons.scss';
@import '../components/user/user.scss';
@import '../components/events/events.scss';
@import '../components/searchBar/search-bar.scss';
@import '../components/ui/styles.scss';

body,
a,
p,
h1,
h2,
h3,
h4,
span {
  font-family: 'Ridley Grotesk';
}

p {
  font-size: 14px;
  color: $grey;
}

h2 {
  font-size: 20px;
  line-height: 26px;
  font-style: normal;
  font-weight: bold;
  line-height: 27px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: $black;
}

h3 {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: $black;
}

.container {
  max-width: 1440px;
  padding: 0 40px;
  margin: 0 auto;
  width: 100%;
}

/* -------------------------------- Toastify -------------------------------- */

.Toastify__toast {
  background-color: $brown-1 !important;
  color: white !important;
}

.Toastify__progress-bar--default {
  background: $grey !important;
}

.MuiFormControl-root {
  width: 100% !important;
}

.MuiInputBase-root {
  border-radius: 8px !important;
}

.MuiFormLabel-root.MuiInputLabel-shrink {
  letter-spacing: 0.04em;
  color: $brown-2;
  font-family: 'Ridley Grotesk';
}

.MuiInputBase-input {
  color: $black !important;
  font-family: 'Ridley Grotesk' !important;
}

.MuiFormLabel-root.Mui-focused {
  color: $brown-1 !important;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: $brown-1 !important;
}

.MuiChip-root {
  background-color: $red !important;
  border-radius: 8px !important;

  span {
    color: white !important;
    font-family: 'Ridley Grotesk' !important;
  }

  .MuiChip-deleteIcon {
    color: white !important;
    width: 20px;
  }
}

.MuiOutlinedInput-root,
.c-date-range-picker__picker {
  border: 1px solid #b2aa96 !important;
  border-radius: 8px !important;

  fieldset {
    display: none !important;
  }
}

.MuiInputLabel-outlined.MuiInputLabel-shrink {
  background-color: white !important;
  display: block !important;
  padding: 0 7px;
  margin-left: -7px;
}

.link {
  color: $red;
  &:hover {
    color: $red;
    text-decoration: underline;
  }
}
