.c-help {
  width: 100%;
  padding: 30px 0;
  min-height: 100vh;
  line-height: 24px;

  &__content-table {
    position: fixed;
    background-color: $brown-4;
    padding: 30px;
    top: 300px;
    width: 300px;
    border-radius: 8px;

    ul {
      padding-left: 15px;
    }

    li {
      list-style: none;
      padding: 0;
      margin-bottom: 5px;
    }

    @media (max-width: 800px) {
      display: none;
    }
  }

  &__content-title {
    font-size: 16px;
  }

  &__table-section-title {
    font-size: 14px;
    margin-top: 30px;
    font-weight: bold;
    color: $red;
  }

  &__table-link {
    padding: 0 !important;
    color: inherit !important;
    background: none !important;
  }

  &__content {
    width: calc(100% - 340px);
    margin-left: auto;
    margin-right: 0;

    @media (max-width: 800px) {
      width: calc(100%);
    }
  }

  &__title {
    text-align: center;
    color: $red;
    margin-top: 40px;
  }

  &__section-title {
    padding-top: 60px;
  }

  li {
    font-size: 14px;
    color: #575756;
    margin-bottom: 10px;
  }

  a {
    color: $red;
    padding: 5px 15px;
    background-color: $brown-5;
    font-weight: 500;
    border-radius: 4px;
  }
}
