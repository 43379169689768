.c-user-sidebar {
  background: $brown-5;
  padding: 40px;
  border-radius: 8px;
  max-width: 300px;
  height: max-content;

  &__title-container {
    display: flex;
    justify-content: space-between;
  }

  &__title {
    margin: 0;
  }

  &__input {
    margin: 10px 0 !important;
    width: 100% !important;
  }

  &__image-container {
    margin-top: 20px;
  }

  &__image-label {
    display: block;
    margin-bottom: 10px;
  }

  &__logo-container {
    width: 100%;
    height: auto;
  }

  &__logo {
    width: 100%;
    height: 220px;
    border-radius: 8px;
    background-size: cover !important;
    background-position: center !important;
  }

  &__edit-button {
    background: transparent;
    border: none;
    display: block;
    margin: 0 auto;
    font-weight: 600;
    text-decoration: underline;
    font-size: 12px;
    line-height: 23px;
    text-align: center;
    letter-spacing: 0.02em;
    color: $brown-2;
  }

  &__submit-button {
    display: block;
    margin: 20px auto;
  }
}

.c-delete-user-modal {
  display: flex;
  align-items: center;
  justify-content: center;

  &__container {
    max-width: 450px;
    background: white;
    padding: 30px;
    border-radius: 8px;
  }

  &__actions-container {
    margin-top: 20px;

    button {
      margin-right: 20px;
    }
  }
}

@media (max-width: 1100px) {
  .c-user-sidebar {
    width: 100%;
    max-width: 100%;
    margin-bottom: 25px;
    padding: 30px;

    h2 {
      margin-top: 0;
    }

    &__submit-button {
      height: fit-content;
      align-self: end;
    }

    &__logo {
      width: 220px;
      height: 220px;
      margin: 0 auto;
    }

    &__form {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 20px;
    }
  }
}

@media (max-width: 700px) {
  .c-user-sidebar {
    &__form {
      display: grid;
      grid-template-columns: 1fr;
    }
  }
}
