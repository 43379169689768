.c-separator {
  display: flex;
  margin-top: 20px;

  &__content {
    min-width: max-content;
    font-size: 14px;
  }

  &__separator {
    content: '';
    margin-left: 30px;
    background: $black;
    height: 1px;
    width: 100%;
    margin-top: 9px;
  }

  &--brown {
    color: $brown-3;

    .c-separator__separator {
      background-color: $brown-3;
    }
  }
}

.c-no-results {
  display: flex;
  max-width: 440px;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  text-align: center;
  flex-wrap: wrap;
  padding: 20px;

  div,
  p,
  h3,
  span {
    display: block;
    width: 100%;
  }

  h3 {
    color: $red;
    font-size: 20px;
    font-weight: lighter;
    letter-spacing: 0.05em;
    text-transform: none;
    margin-bottom: 5px;
  }

  span {
    font-size: 16px;
    font-weight: 600;
    color: $red;
    margin-bottom: 15px;
  }

  img {
    margin: auto;
    width: 80px;
    margin-bottom: 20px;
  }
}

.c-video-image {
  width: 100%;
  position: relative;
  max-width: 800px;
  margin: 0 auto;
  border: 2px solid $red;
  border-radius: 6px;
  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    width: 60px;
    height: 60px;
    background: url('/img/icons/play.svg');
    background-size: cover;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.25));
  }

  img {
    width: 100%;
  }
}

.c-video-modal {
  max-width: 800px;
  max-height: 450px;
  width: calc(100% - 20px);
  margin: auto;

  @media screen and (max-width: 600px) {
    max-height: 340px;
  }
}
