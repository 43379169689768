.c-search-bar {
  display: flex;
  flex-wrap: wrap;

  &__main {
    width: 100%;
    display: flex;
  }

  &__button {
    min-width: 58px;
    display: flex;
    align-items: center;
    margin-left: 10px;
  }

  &__date-picker {
    margin-left: 10px;
    min-width: 300px;

    @media (max-width: 600px) {
      min-width: 0;
    }
  }

  &__filter-button {
    display: flex;
    align-items: center;
    background-color: #f0f0ef;
    min-width: 58px;
    margin-left: 20px;

    &--active {
      background-color: $red;
    }
  }

  &__filters {
    margin-top: 20px;
    padding: 20px;
    background-color: #f0f0ef;
    display: flex;
    align-items: center;
    width: 100%;
    height: fit-content;
    justify-content: space-between;
    min-height: 103px;
    border-radius: 8px;
  }

  &__categories {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }

  &__categories-input,
  &__municipios-input {
    display: block;
    width: calc(50% - 10px);
    margin-right: 10px;
  }

  &__clear-filter-button {
    display: flex;
    align-items: center;
    background-color: transparent;
    min-width: 54px;
    margin-left: 20px !important;

    &:hover {
      background-color: #f0f0ef;
    }
  }

  &__format {
    margin-top: 0 !important;
  }

  &__filters-loader {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__filter-icon {
    width: 32px;
    height: 32px;
    background-image: url('/img/icons/filter.svg');
    background-size: cover !important;

    &--active {
      background-image: url('/img/icons/filter-white.svg');
      background-size: cover !important;
    }
  }

  &__clear-filter-icon {
    min-width: 32px;
    height: 34px;
    background-image: url('/img/icons/clear-filter.svg');
    background-size: cover !important;
  }

  &__price {
    margin: 0 15px;
  }
}

@media (max-width: 1350px) {
  .c-search-bar {
    &__price {
      width: calc(100% - 545px) !important;
    }
  }
}

@media (max-width: 800px) {
  .c-search-bar {
    &__format-container {
      width: 100%;
      margin-bottom: 20px;
    }

    &__format {
      justify-content: space-between;
    }

    &__price {
      width: calc(100% - 120px) !important;
    }

    &__main {
      flex-wrap: wrap;
    }

    &__date-picker-container {
      width: calc(100% - 144px);
    }

    &__date-picker {
      margin: 15px 0 0 0;
      width: 100%;
    }

    &__filters {
      flex-wrap: wrap;
    }

    &__categories {
      margin-right: 0;
      margin-bottom: 15px;
    }

    &__filter-button,
    &__button {
      margin: 15px 0 0 10px;
    }

    &__clear-filter-button {
      margin-left: auto !important;
      margin: 0 auto;
    }
  }
}

@media (max-width: 600px) {
  .c-search-bar {
    &__format {
      justify-content: space-between;

      button {
        padding: 7px 8px;
        margin-right: 7px;
        font-size: 12px;
      }
    }

    &__categories-input,
    &__municipios-input {
      width: 100%;
      margin-right: 0;
    }

    &__municipios-input {
      margin-top: 10px;
    }
  }
}
