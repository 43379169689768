.c-confirm-user {
  background: white;
  background-size: cover;
  border-radius: 8px;
  margin: 100px auto;
  max-width: 620px;
  max-height: 650px;
  display: flex;
  flex-wrap: wrap;
  padding: 40px;
  justify-content: center;

  @media (max-width: 450px) {
    padding: 20px;
  }

  &__thanks-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    height: fit-content;
  }

  &__red-title {
    color: $red;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 19px;
    text-align: center;
    letter-spacing: 0.05em;
    display: block;
    width: 100%;
    margin: 10px 0;
  }

  &__red-subtitle {
    color: $red;
    font-style: normal;
    font-weight: normal;
    display: block;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    letter-spacing: 0.04em;
  }

  &__description {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    letter-spacing: 0.02em;
    color: $grey;
    margin-top: 20px;
  }

  &__social-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    height: fit-content;
    margin-top: 20px;
  }

  &__follow-cta {
    font-style: normal;
    font-weight: normal;
    width: 100%;
    font-size: 12px;
    line-height: 19px;
    text-align: center;
    letter-spacing: 0.04em;
    text-align: center;
  }

  &__social-list {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;

    li {
      list-style: none;
      margin: 0 10px;

      img {
        width: 24px;
      }
    }
  }
}
