.c-intranet {
  display: flex;
  min-height: calc(100vh - 447px);

  &__feed {
    width: 100%;
    margin-left: 40px;
    align-items: flex-start;
  }

  &__header {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
}

@media (max-width: 1100px) {
  .c-intranet {
    flex-wrap: wrap;

    &__feed {
      margin-left: 0px;
    }
  }
}
