@font-face {
  font-family: 'Ridley Grotesk';
  src: url('RidleyGrotesk-Light.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Ridley Grotesk';
  src: url('RidleyGrotesk-Bold.otf') format('opentype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Ridley Grotesk';
  src: url('RidleyGrotesk-Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Ridley Grotesk';
  src: url('RidleyGrotesk-SemiBold.otf') format('opentype');
  font-weight: 600;
  font-style: normal;
}
