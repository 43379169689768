.c-date-range-picker {
  box-shadow: none !important;

  &__picker {
    .MuiInputBase-root {
      border: none !important;
      margin: 11px 8px 7px 8px !important;

      &::before {
        content: none !important;
      }
    }

    input {
      padding: 9px !important;
    }

    .MuiInputLabel-shrink {
      background-color: white !important;
      display: block !important;
      padding: 0 7px;
      margin-left: 5px !important;
      top: -6px !important;
    }

    .MuiFormHelperText-filled {
      margin: 5px 0 0 15px;
    }
  }
}

.c-input-Mui {
  &--light-grey {
    .c-material-input-div__label,
    .MuiInputLabel-outlined.MuiInputLabel-shrink {
      background-color: $light-grey !important;
    }
  }

  &--brown-5 {
    .c-material-input-div__label,
    .MuiInputLabel-outlined.MuiInputLabel-shrink {
      background-color: $brown-5 !important;
    }
  }
}

.c-range-picker {
  label {
    position: absolute;
    background-color: white;
    display: block;
    padding: 0 7px;
    margin-left: 5px;
    top: -7px;
    left: 0;
    z-index: 2;
    font-family: 'Ridley Grotesk' !important;
    font-size: 11px;
  }

  input {
    height: 58px;
    border-radius: 8px;
    background: transparent;
  }
}

.c-image-uploader {
  background: rgba(0, 0, 0, 0.05);
  border: 1px dashed #b2aa96;
  min-height: 250px;
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-wrap: wrap;
  border-radius: 8px;

  &__error {
    font-size: 12px;
    color: $red;
    padding: 0 30px;
  }
}

.c-multiple-select {
  width: 100%;
  height: fit-content;
  display: inline-block;
}

.c-material-input-div {
  width: 100%;
  border: 1px solid $brown-1;
  border-radius: 8px;
  padding: 9px;
  display: flex;
  align-items: center;
  min-height: 56px;
  position: relative;
  margin-top: 7px;

  &__label {
    position: absolute;
    display: block;
    background: white;
    padding: 0 5px;
    top: -6px;
    left: 8px;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: 0.04em;
    color: $brown-2;
  }

  &__error {
    color: $red;
  }
}

.c-map-input {
  position: relative;

  &__input {
    margin-bottom: 20px !important;
  }

  &__map {
    border-radius: 8px !important;
    overflow: hidden !important;
  }

  &__marker {
    min-width: 80px;
    min-height: 30px;
    padding: 10px;
    text-align: center;
    transform: translateX(-50%);
    border-radius: 8px;
    background-color: $red;
    position: relative;
    color: #ffff;
    width: fit-content;

    &::before {
      content: ' ';
      width: 10px;
      height: 10px;
      background: $red;
      position: absolute;
      transform: rotate(45deg);
      margin: auto;
      left: 0;
      right: 0;
      top: -4px;
    }
  }

  &__dropdown-container {
    position: absolute;
    top: 60px;
    width: 100%;
    height: 140px;
    border-radius: 8px;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
    overflow-y: scroll;
    padding: 10px 20px;
    background: $brown-5;
    z-index: 2;
    left: 0;
  }

  &__suggestion {
    display: block;
    margin-bottom: 5px;

    padding: 0px;

    &:hover {
      margin: 0 0 5px 0;
      padding: 0px;
    }

    &--active {
      text-decoration: underline;
      margin: 0 0 5px 0;
      padding: 0px;
    }
  }
}

.c-rich-text {
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  .ql-toolbar {
    width: 100%;
    border: none !important;
    border-bottom: solid 1px $black !important;
  }

  .ql-container {
    width: 100%;
    border: none !important;
  }

  .ql-editor {
    min-height: 250px;
  }
}

.c-tickets-input {
  &__switch {
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    span {
      display: inline-block;
      margin-right: 20px;
    }
  }

  &__tickets {
    min-height: 150px;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
  }

  &__new-ticket {
    display: flex;
    width: 100%;
    align-items: center;
    margin-bottom: 10px;
  }

  &__ticket-name {
    width: 60% !important;
    margin-right: 10px !important;
  }

  &__ticket-price {
    width: 30% !important;
    margin-right: 10px !important;
  }

  &__add-button {
    min-width: 33px;
    height: fit-content;
    font-size: 24px;
    line-height: 16px;
    background-color: $red;
    color: white;
    padding: 10px;
  }

  &__tag {
    display: inline-block;
    margin: 6px !important;
  }
}

.c-file-uploader {
  &__files {
    padding: 12px 20px;
    margin: 20px 0 10px 0;
    background-color: $brown-5;
    border-radius: 8px;
  }

  &__attached-file {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 7px 0;
  }

  &__file {
    display: flex;
    align-items: center;
    svg {
      margin-right: 10px;
    }
  }
}

.c-tag {
  &--small {
    padding: 3px 4px;
    background-color: #b2aa96 !important;
  }
}

.c-slider {
  margin-bottom: 10px;

  &__slider {
    padding: 8px 0 !important;
  }

  &__rail {
    background-color: $black !important;
  }

  &__track {
    background-color: $red !important;
  }

  &__thumb {
    background-color: $red !important;
    border: 1px solid $black !important;
  }

  &__value-label {
    transform: scale(1) translateY(52px) !important;
    left: calc(-50% - 6px) !important;

    & > span {
      height: 15px !important;
      transform: none !important;
      width: fit-content !important;

      & > span {
        transform: none !important;
        padding: 2px 4px;
        background-color: $red;
        border-radius: 6px !important;
        color: white;
      }
    }
  }
}

@media (max-width: 600px) {
  .gCrjsk {
    align-self: center !important;
  }
}
