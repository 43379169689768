.c-feed-events {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  min-height: 800px;

  &--align-center {
    align-items: center;
  }

  &__month-container {
    width: 100%;
  }
}

.c-card-event {
  display: flex;
  margin: 40px 0 80px 0;

  &--outdated {
    opacity: 0.4;
  }

  &__info-container {
    width: 100%;
    padding: 0 25px;
  }

  &__date {
    font-size: 12px;
    line-height: 19px;
    letter-spacing: 0.03em;
    color: $grey;
  }

  &__category {
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 5px;
    font-size: 12px;
    background: $brown-3;
    color: white;
    padding: 1px 5px;
    border-radius: 4px;
  }

  &__image {
    min-width: 320px;
    height: 223px;
    border-radius: 0 0 80px 0;
    background-position: center center !important;
    background-size: cover !important;
    background-repeat: no-repeat;
    cursor: pointer;

    &--big {
      @extend .c-card-event__image;
      height: 500px;
      min-width: 500px;
      cursor: default;
    }
  }

  &__buttons-container {
    display: flex;
    align-items: center;
  }

  &__web {
    min-width: 30px;
    margin: 0 35px 0 15px;
  }

  &__price {
    display: block;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0.04em;
    color: $grey;
    margin: 10px 0;
    color: black;
    cursor: pointer;
  }

  &__price-tooltip {
    background: #fcfcfc !important;
    border-radius: 6px;
    padding: 7px 10px !important;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
    position: relative;
    max-width: 400px;
    width: min-content !important;
  }

  &__price-tag {
    margin: 5px !important;
  }

  &__title {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 0.02em;
    color: black;
    margin: 10px 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    &--pending-tag {
      margin-right: 15px !important;
      margin-bottom: 15px !important;
    }

    &:hover {
      text-decoration: none;
    }
  }

  &__details-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 10px;

    .c-tag {
      margin: 5px 10px 5px 0;
      height: 25px;
      background-color: $brown-3 !important;
    }
  }

  &__address {
    display: block;
    color: $black;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.04em;
    color: black;
    margin-right: 10px;
  }

  &__description {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.04em;
    color: #565656;

    a {
      color: $red !important;
    }
  }
}

.c-calendar {
  background-image: url('/img/icons/calendar.svg');
  padding: 23px 3px 20px 3px;
  background-size: cover;
  min-width: 50px;
  width: 50px;
  min-height: 81px;
  height: 81px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;

  &__one {
    font-weight: 600;
    font-size: 29px;
    line-height: 19px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #3c3c3b;
  }

  &__two {
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    margin-top: 3px;
    letter-spacing: 0.04em;
    color: white;
  }
}

.c-attached-files {
  width: 100%;

  &__file-container {
    display: flex;
    align-items: center;
    margin: 10px 0;
  }
}

.c-event-details {
  &__box {
    background-color: $brown-4;
    border-radius: 8px;
    overflow: hidden;
    display: flex;
  }

  &__info {
    width: calc(100% - 500px);
    padding: 40px;
    display: flex;
    flex-wrap: wrap;
  }

  &__time {
    width: calc(50% - 35px);
    margin: 0 35px 35px 0;
  }

  &__location {
    width: 50%;
  }

  &__title {
    font-size: 14px;
    color: black;
    font-weight: 600;
    margin-top: 0;
    display: flex;
    align-items: center;

    svg {
      margin-right: 5px;
    }
  }

  &__span {
    margin-left: 29px;
    display: block;
    font-size: 14px;
    line-height: 24px;

    &-highlight {
      margin-left: 29px;
      font-size: 14px;
      font-weight: 600;
      line-height: 24px;
    }
  }

  &__button {
    margin-left: 29px;
    display: block;
    margin-top: 5px;
    font-size: 12px;
    font-weight: 600;
    color: $red;
  }

  &__promoter {
    width: 100%;
    display: flex;
    align-items: center;
  }

  &__promoter-image {
    width: 130px;
    height: 130px;
    margin-left: 35px;
  }

  &__map {
    width: 500px;
    height: 400px;
  }
}

@media (max-width: 800px) {
  .c-card-event {
    flex-wrap: wrap-reverse;
    position: relative;

    &__image {
      width: 100%;
      min-width: 0;
    }

    &__info-container {
      padding: 5px 0 0 0;
    }
  }

  .c-calendar {
    position: absolute;
    top: 20px;
    left: 20px;
    filter: drop-shadow(0px 3px 15px rgba(0, 0, 0, 0.4));
  }
}

@media (max-width: 1100px) {
  .c-card-event {
    &__image--big {
      height: 350px;
      min-width: 350px;
    }
  }

  .c-event-details {
    &__box {
      flex-wrap: wrap;
    }

    &__info,
    &__map {
      width: 100%;
    }
  }
}

@media (max-width: 550px) {
  .c-card-event {
    &__image--big {
      height: 350px;
      min-width: 0;
    }
  }

  .c-event-details {
    &__info {
      padding: 20px;
    }

    &__time,
    &__location {
      width: 100%;
      margin: 0 0 20px 0;
    }

    &__promoter {
      flex-wrap: wrap;
    }

    &__promoter-image {
      margin: 10px auto 0;
    }
  }
}
